<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/score-output' }">
        <app-i18n
          code="entities.scoreOutput.menu"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.scoreOutput.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.scoreOutput.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-score-output-view-toolbar
        v-if="record"
      ></app-score-output-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.scoreStatus.label"
          :value="presenter(record, 'scoreStatus')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.teacher.label"
          :permission="fields.teacher.readPermission"
          :url="fields.teacher.viewUrl"
          :value="presenter(record, 'teacher')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.examOutput.label"
          :permission="fields.examOutput.readPermission"
          :url="fields.examOutput.viewUrl"
          :value="presenter(record, 'examOutput')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.score.label"
          :value="presenter(record, 'score')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ScoreOutputViewToolbar from '@/modules/score-output/components/score-output-view-toolbar.vue';
import { ScoreOutputModel } from '@/modules/score-output/score-output-model';

const { fields } = ScoreOutputModel;

export default {
  name: 'app-score-output-view-page',

  props: ['id'],

  components: {
    [ScoreOutputViewToolbar.name]: ScoreOutputViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'scoreOutput/view/record',
      loading: 'scoreOutput/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'scoreOutput/view/doFind',
    }),

    presenter(record, fieldName) {
      return ScoreOutputModel.presenter(record, fieldName);
    },
  },
};
</script>
